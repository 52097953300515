<template>
  <div v-if="template" class="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
    <div class="flex justify-between items-center">
      <h3 class="text-3xl font-medium leading-6 text-gray-900">{{$t("template.deviceTemplate")}}</h3>
      <div class="inline-flex shadow-sm rounded-md">
        <router-link to="/admin/settings#deviceTemplates" @click="goBack(true)" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <ArrowLeftIcon class="h-5 w-5 mr-2" aria-hidden="true" />
          {{$t("template.backToSettingsList")}}
        </router-link>
        <button :title="$t('template.removeTemplate')" @click="$refs.modal.show()" type="button" :class="template.isSpecial && 'opacity-60 pointer-events-none'" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <TrashIcon class='h-5 w-5' aria-hidden="true" />
        </button>
      </div>
    </div>
    <div class="mt-5">
      <dl class="divide-y divide-gray-200">
        <div class="py-2 sm:flex items-center">
          <dt class="sm:w-1/3 text-sm text-gray-500">{{$t("active")}}?</dt>
          <dd class="sm:w-2/3 flex items-center text-sm text-gray-900 font-medium sm:col-span-2 sm:mt-0">
            <span class="flex-grow uppercase">{{ template.isActive ? $t('yes') : $t('no') }}</span>
            <span class="ml-4 flex-shrink-0">
              <p
                v-if="
                  !template.activeVersion.installationFormSchema || template.activeVersion.installationFormSchema.length == 0 ||
                  !template.activeVersion.inspectionFormSchema || template.activeVersion.inspectionFormSchema.length == 0"
                class="py-2 text-sm text-red-400"
              >
                  {{$t("template.activateTemplate")}}
              </p>
              <LabeledSwitch
                v-else
                :pkey="0"
                :border="false"
                :initial="template.isActive"
                @change="activate"
              />
            </span>
          </dd>
        </div>
        <div class="py-2 sm:flex items-center">
          <dt class="sm:w-1/3 text-sm text-gray-500">{{$t("name")}}</dt>
          <dd v-show="editName" class="sm:w-2/3 flex items-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow">
              <InputWithErrorVue v-model="name" id="name-input" type="text" />
            </span>
            <span class="ml-4 flex-shrink-0">
              <button @click.stop="saveEdit('name')" type="button" :class="['relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
                  <CheckIcon class='h-5 w-5' aria-hidden="true" />
              </button>
            </span>
          </dd>
          <dd v-show="!editName" class="sm:w-2/3 flex items-center text-sm text-gray-900 font-medium sm:col-span-2 sm:mt-0">
            <span class="flex-grow">{{ name }}</span>
            <span class="ml-4 flex-shrink-0">
              <button @click.stop="editName = true" type="button" id="edit-icon" :class="['relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
                  <PencilIcon class='h-5 w-5' aria-hidden="true" />
              </button>
            </span>
          </dd>
        </div>
        <div class="py-2 sm:flex items-center">
          <dt class="sm:w-1/3 text-sm text-gray-500 capitalize">{{$t("description")}}</dt>
          <dd v-show="editDescr" class="sm:w-2/3 flex items-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow">
              <InputWithErrorVue v-model="descr" id="descr-input" type="text" />
            </span>
            <span class="ml-4 flex-shrink-0">
              <button @click.stop="saveEdit('descr')" type="button" :class="['relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
                  <CheckIcon class='h-5 w-5' aria-hidden="true" />
              </button>
            </span>
          </dd>
          <dd v-show="!editDescr" class="sm:w-2/3 flex items-center text-sm text-gray-900 font-medium sm:col-span-2 sm:mt-0">
            <span class="flex-grow">{{ descr }}</span>
            <span class="ml-4 flex-shrink-0">
              <button @click.stop="editDescr = true" type="button" id="edit-icon" :class="['relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
                <PencilIcon class='h-5 w-5' aria-hidden="true" />
              </button>
            </span>
          </dd>
        </div>
        <div class="py-2 sm:flex items-center">
          <dt class="sm:w-1/3 text-sm text-gray-500">{{ $t('template.mainLanguage') }}</dt>
          <dd class="sm:w-2/3 flex items-center text-sm uppercase text-gray-900 font-medium sm:col-span-2 sm:mt-0">
            <span class="flex-grow">{{ template.language }}</span>
          </dd>
        </div>
        <div class="py-2 sm:flex items-center">
          <dt class="sm:w-1/3 text-sm text-gray-500">{{$t("template.parameterSchemeVersion")}}</dt>
          <dd v-show="editVer" class="sm:w-2/3 flex items-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <span class="flex-grow">
              <NativeSelect
                :options="schemaVersions"
                v-model="activeVersionId"
              />
            </span>
            <span class="ml-4  flex-shrink-0 relative z-0 inline-flex shadow-sm rounded-md">
              <button @click="emitEdit" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
                <PlusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button title="Wybierz aktywną wersję" @click="chooseVersion(activeVersionId)" type="button" id="edit-icon" :class="['-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
                <CheckIcon class='h-5 w-5' aria-hidden="true" />
              </button>
            </span>
          </dd>
          <dd v-show="!editVer" class="sm:w-2/3 flex items-center text-sm text-gray-900 font-medium sm:col-span-2 sm:mt-0">
            <span class="flex-grow">{{ `v${template.activeVersion.versionNumber}, ${this.$t("template.added")}: ${new Date(template.activeVersion.createdAt).toLocaleString('sv')}` }}</span>
            <span :class="template.parentTemplate && 'opacity-60 pointer-events-none'" class="ml-4  flex-shrink-0 relative z-0 inline-flex shadow-sm rounded-md">
              <button :title="$t('template.addSchemeVersion')" @click="emitEdit" type="button" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
                <PlusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button :title="$t('template.chooseActiveVersion')" @click="editVer = true" type="button" id="edit-icon" :class="['-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
                <PencilIcon class='h-5 w-5' aria-hidden="true" />
              </button>
            </span>
          </dd>
        </div>
        <div class="sm:flex">
          <dt class="sm:w-1/3 py-4 text-sm text-gray-500">
            {{$t("template.parameterScheme")}}
          </dt>
          <dd class="sm:w-2/3 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-gray-200">
              <li
                v-for="(field, index) of template.activeVersion.parametersSchema"
                :key="field.name"
                class="flex items-center justify-between py-3 text-sm"
              >
                <div class="flex justify-between w-0 flex-1 font-medium text-gray-900 items-center">
                  <span class="w-0 flex-1 truncate">
                    {{ index + 1 }}. {{ field.label }}
                  </span>
                  <KeyIcon v-if="field.barcodeScanner" class="text-gray-400 w-4 w-4" />
                </div>
                <div class="ml-4 flex flex-shrink-0 space-x-4">
                  <span class="text-gray-400" aria-hidden="true">{{ field.type == 'string' ? $t("template.text") : $t("template.list") }}</span>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div v-for="form in formTemplates" :key="form.title" class="flex items-center">
          <dt class="sm:w-1/3 py-4 text-sm text-gray-500">
            {{ form.title }}
          </dt>
          <dd class="sm:w-2/3 flex justify-end flex-grow text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <router-link :to="`/admin/device-templates/${template.id}/form-templates/${form.type}`"  type="button" :class="['relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
              <PencilIcon v-if="form.schema && form.schema.length" class='h-5 w-5' aria-hidden="true" />
              <PlusIcon v-else class='h-5 w-5' aria-hidden="true" />
            </router-link>
          </dd>
        </div>
        <router-link
          to="/admin/settings#deviceTemplates"
          class="w-full mt-6 sm:hidden inline-flex items-center justify-center rounded-md border border-transparent bg-ifgreen px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2 sm:w-auto">
          <ArrowLeftIcon class="w-5 h-5 mr-1" />
          {{$t("template.backToSettingsList")}}
        </router-link>
      </dl>
    </div>
  </div>
  <Modal
    v-if="template"
    ref="modal"
    :title="template.name"
    :text="hasDevices
    ? $t('public.infos.cannotDeleteTemplate')
    : $t('public.infos.deleteTemplateConfirm')"
    :cancel-button-label="hasDevices ? 'Ok' : $t('cancel')"
  >
    <button
      v-if="!hasDevices"
      type="button"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
      @click="deleteTemplate"
    >
      {{$t("delete")}}
    </button>
  </Modal>
</template>
<script>
import { mapState } from "vuex";
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { TrashIcon ,ArrowLeftIcon, PencilIcon, PlusIcon, CheckIcon, PaperClipIcon, KeyIcon } from "@heroicons/vue/24/outline";
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import LabeledSwitch from "@/components/inputs/LabeledSwitch.vue";
import NativeSelect from "@/components/inputs/NativeSelect.vue";
import Modal from "@/components/layout/Modal.vue";
export default {
  components: {
    PaperClipIcon,
    InputWithErrorVue,
    PencilIcon,
    CheckIcon,
    PlusIcon,
    TrashIcon,
    ArrowLeftIcon,
    KeyIcon,
    LabeledSwitch,
    NativeSelect,
    Modal
  },
  emits: ["change-view"],
  data() {
    return {
      name: null,
      descr: null,
      ver: null,
      editName: false,
      editDescr: false,
      editVer: false,
      activeVersionId: null
    };
  },
  computed: {
    ...mapState(["organisation", "deviceTemplates"]),
    schemaVersions () {
      console.log(this.template)
      return this.template.versions.map(v => {return {name: `v${v.versionNumber}, ${this.$t("template.added")}: ${new Date(v.createdAt).toLocaleString('sv')}`, value: v.id}})
    },
    template () {
      return this.deviceTemplates && this.deviceTemplates.find(temp => {return temp.id == this.$route.params.deviceTemplateId})
    },
    hasDevices() {
      return this.template.devices && this.template.devices.length || this.template.customDevices && this.template.customDevices.length
    },
    formTemplates () {
      return [
        {title: this.$t("template.installationFormScheme"), schema: this.template.activeVersion.installationFormSchema, type: "installation"},
        {title: this.$t("template.inspectionFormScheme"), schema: this.template.activeVersion.inspectionFormSchema, type: "inspection"}
      ]
    }
  },
  async created () {
    this.name = this.template.name;
    this.descr = this.template.description;
    this.ver = this.template.activeVersion;
    this.activeVersionId = this.template.activeVersion.id;
  },
  methods: {
    chooseVersion(id) {
      const obj = { activeVersion: `/device_template_schema_versions/${id}` };
      this.patchTemplate(obj);
      this.editVer = false;
    },
    saveEdit(str) {
      let obj = {};
      switch (str) {
        case "name":
          obj = { name: this.name };
          this.patchTemplate(obj);
          this.editName = false;
          return;
        case "descr":
          obj = { description: this.descr };
          this.patchTemplate(obj);
          this.editDescr = false;
          return;
      }
    },
    activate(e) {
      if (this.template?.isActive == e.state) return;
      const obj = { isActive: e.state };
      this.patchTemplate(obj);
    },
    patchTemplate(obj) {
      if (!this.template) return;
      this.$store.dispatch("setLoading", true);
      api.patch(`/device_templates/${this.template.id}`, obj, patchHeaders).then(() => {
        this.$store.dispatch("getDeviceTemplates", false).then(() => {
          this.$store.dispatch("setLoading", false);
        })
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("setLoading", false);
      });
    },
    deleteTemplate() {
      this.$store.dispatch("setLoading", true);
      api.delete(`/device_templates/${this.template.id}`).then(() => {
        this.$store.dispatch("getDeviceTemplates", false).then(() => {
          this.$store.dispatch("setLoading", false);
          this.$router.push({name: 'admin-settings', hash: '#deviceTemplates'})
        })
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("setLoading", false);
      });
    },
    emitEdit() {
      this.$emit("change-view", this.template);
    }
  }
};
</script>
