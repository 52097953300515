<template>
  <div class="relative max-w-7xl mx-auto px-4 py-6 pb-12 sm:px-6 lg:px-8">
    <h3 class="text-3xl font-medium leading-6 text-gray-900">
      {{ $route.params.deviceTemplateId == 'new' ? $t('add') : $t('edit') }} {{$t('device.deviceTemplate')}}
    </h3>
    <div class="sticky top-20 -mt-10 flex-shrink-0 relative z-10 flex justify-end">
      <div class="inline-flex shadow-sm rounded-md">
        <button 
          :title="$t('template.goBackDiscard')"
          @click="goBack(true)"
          type="button"
          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen"
        >
          <ArrowLeftIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          :title="$t('template.goBackSave')"
          type="button"
          @click="possiblyGoBack"
          class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen"
        >
          <DocumentArrowDownIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          :title="$t('template.clearData')"
          type="button"
          @click="clearAllData"
          class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen"
        >
          <ArrowPathIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          :title="$t('template.saveData')"
          @click="sendData"
          type="button"
          id="edit-icon"
          :class="['-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]"
        >
          <CheckIcon class='h-5 w-5' aria-hidden="true" />
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row mt-8 relative">
      <div class="md:w-3/5 md:border-r md:border-gray md:pr-6 lg:pr-8">
        <div class="mt-2 relative">
          <InputWithErrorVue
            v-model="name"
            id="name"
            name="name"
            type="text"
            :label="$t('name')"
            :placeholder="$t('name')"
            errorText=""
            :validation="v$.name"
          />
          <TranslationInput  
            v-if="name != ''"
            v-model="nameTranslations"
            :mainLanguage="mainLanguage" 
            :translatedValue="name" 
            buttonClasses="top-8 right-3"
          />
        </div>
        <div class="mt-2 relative">
          <InputWithErrorVue
            v-model="description"
            id="description"
            name="description"
            type="text"
            :label="$t('description')"
            :placeholder="$t('description')"
            :validation="v$.description"
          />
          <TranslationInput  
            v-if="description != ''"
            v-model="descriptionTranslations"
            :mainLanguage="mainLanguage" 
            :translatedValue="description" 
            buttonClasses="top-8 right-3"
          />
        </div>
        <div class="block text-sm font-medium text-gray-700 mt-5">{{$t("template.parameters")}}</div>
        <ul v-if="schema.length" role="list" class="mt-1 divide-y divide-gray-200 rounded-md shadow border border-gray-200">
          <TemplateField
            v-for="(item, index) in schema"
            v-model="schema[index]"
            :key="item.name"
            :index="index"
            :schema="schema"
            :areFieldNamesUnique="areFieldNamesUnique"
            :mainLanguage="mainLanguage"
            @move-up="moveField(index, index-1)"
            @move-down="moveField(index, index+1)"
            @delete="deleteField(index)"
          />
        </ul>
        <button
          class="block bg-ifgreen hover:bg-ifgreen-dark text-white mt-2 w-full rounded-md"
          @click="addField"
        >
          <div class="px-3 py-3 flex justify-center">
            <PlusIcon class="h-6 w-6 mr-2" aria-hidden="true" /> {{$t('template.addField')}}
          </div>
        </button>
      </div>
      <div class="md:w-2/5 md:pl-6 lg:pl-8">
        <div class="sticky top-24">
          <div class="block text-sm font-medium text-gray-700 mt-3 pb-6 md:pl-8 md:-ml-8 border-b border-gray-200">
            {{$t('template.formPreview')}}
          </div>
          <TemplateForm
            :preview="true"
            :name="name"
            :description="description"
            :descriptionTranslations="descriptionTranslations"
            :schema="schema"
            @submit="submit"
            @invalid="invalid"
          />
        </div>
      </div>
    </div>
  </div>
  <Modal
    ref="modal"
    title=""
    :text="$t('settings.unfinishedConfig')"
  >
    <button
      @click="fetchLocalStorage"
      type="button"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm"
    >
      {{$t("restore")}}
    </button>
    <button
      @click="fetchPreviousTemplate"
      type="button"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm"
    >
      {{$t("startNew")}}
    </button>
  </Modal>
</template>
<script>
import TemplateField from "@/components/admin/DeviceTemplate/TemplateField.vue";
import useVuelidate from "@vuelidate/core";
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import TranslationInput from "@/components/inputs/TranslationInput.vue";
import TemplateForm from "@/components/admin/DeviceTemplate/TemplateForm.vue";
import Modal from "@/components/layout/Modal.vue";
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { DocumentArrowDownIcon, ArrowPathIcon ,ArrowLeftIcon, PlusIcon, CheckIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    TemplateField,
    InputWithErrorVue,
    TranslationInput,
    DocumentArrowDownIcon,
    ArrowPathIcon,
    ArrowLeftIcon,
    CheckIcon,
    PlusIcon,
    TemplateForm,
    Modal
  },
  props: { previousTemplate: Object },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    // if needed, open the modal to fetch the template from local storage instead
    const na = localStorage.getItem("templateName");
    const de = localStorage.getItem("templateDescription");
    const sc = localStorage.getItem("templateSchema");

    if ((na && na != "") || (de && de != "") || (sc && sc != "[]")) {
      this.$refs.modal.show();
    } else this.fetchPreviousTemplate();
  },
  data() {
    return {
      name: "",
      nameTranslations: {},
      description: "",
      descriptionTranslations: {},
      latestVersion: 1,
      previousVersions: [],
      schema: [],
      previousSchema: null,
    };
  },
  computed: {
    ...mapState(["organisation"]),
    areFieldNamesUnique() {
      for (var i = 0; i < this.schema.length - 1; i++) {
        for (var j = i + 1; j < this.schema.length; j++) {
          if (this.schema[i].name == this.schema[j].name)
            return false;
        }
      }
      return true;
    },
    mainLanguage () {
      return this.previousTemplate?.language ? this.previousTemplate?.language : this.$i18n.locale 
    }
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage(`${this.$t('template.reqField')}`, required),
      },
      description: {
        required: helpers.withMessage(`${this.$t('template.reqField')}`, required),
      },
    };
  },
  watch: {
    name(val) {
      localStorage.setItem("templateName", val);
    },
    nameTranslations: {
      deep: true,
      handler(val) {
        localStorage.setItem("templateNameTranslations", JSON.stringify(val));
      }
    },
    description(val) {
      localStorage.setItem("templateDescription", val);
    },
    descriptionTranslations: {
      deep: true,
      handler(val) {
        localStorage.setItem("templateDescriptionTranslations", JSON.stringify(val));
      }
    },
    schema: {
      deep: true,
      handler(val) {
        console.log("schema changed to:", val);
        console.log("previous schema: ", this.previousSchema)
        localStorage.setItem("templateSchema", JSON.stringify(val));
        console.log(this.areFieldNamesUnique)
      },
    },
  },
  methods: {
    moveField (from, to) {
      this.schema.splice(to, 0, this.schema.splice(from, 1)[0]);
    },
    deleteField(index) {
      this.schema.splice(index, 1);
    },
    addField() {
      this.schema.forEach(field => {field.unfolded = false})
      this.schema.push({
        unfolded: true,
        label: "",
        name: "",
        type: "string",
        barcodeScanner: true,
        choices: [],
        conditionalLogic: [[]],
      });
    },
    submit() {
      this.$notify({
        title: 'Test',
        text: `${this.$t('public.infos.submitFormSuccess')}`,
        type: 'success'
      })
    },
    invalid() {
      this.$notify({
        title: 'Test',
        text: `${this.$t('public.infos.invalidForm')}`,
        type: 'warning'
      })
    },
    fetchPreviousTemplate() {
      if (this.previousTemplate) {
        // fetching previous template (if it exists)
        this.name = this.previousTemplate?.name;
        this.nameTranslations = this.previousTemplate?.nameTranslations;
        this.description = this.previousTemplate?.description;
        this.descriptionTranslations = this.previousTemplate?.descriptionTranslations;
        this.schema = this.previousTemplate?.activeVersion?.parametersSchema;
        this.previousSchema = this.previousTemplate?.activeVersion?.parametersSchema.slice();
        this.previousVersions = this.previousTemplate?.versions;
        this.fetchLatestVersion(this.previousTemplate?.versions);
      }
      this.$refs.modal.close();
    },
    sanitizeLogic(logic) {
      if (!logic) return [[]];
      const sanitizedLogic = [];
      logic.forEach(alternative => {
        const sanitizedAlternative = alternative.filter(condition => {return condition.field})
        if (sanitizedAlternative.length || !sanitizedLogic.length) sanitizedLogic.push(sanitizedAlternative)
      })
      return sanitizedLogic
    },
    fetchLocalStorage() {
      this.$refs.modal.close();
      const na = localStorage.getItem("templateName");
      const natr = localStorage.getItem("templateNameTranslations");
      const de = localStorage.getItem("templateDescription");
      const detr = localStorage.getItem("templateDescriptionTranslations");
      const sc = localStorage.getItem("templateSchema");
      if (na) this.name = na;
      if (natr) this.nameTranslations = JSON.parse(natr);
      if (de) this.description = de;
      if (detr) this.descriptionTranslations = JSON.parse(detr);
      if (sc) this.schema = JSON.parse(sc);
      if (this.previousTemplate) {
        this.previousSchema = this.previousTemplate?.activeVersion?.parametersSchema;
      }
    },
    wipeLocalStorage() {
      localStorage.removeItem("templateName");
      localStorage.removeItem("templateNameTranslations");
      localStorage.removeItem("templateDescription");
      localStorage.removeItem("templateDescriptionTranslations");
      localStorage.removeItem("templateSchema");
    },
    possiblyGoBack() {
        if (confirm(`${this.$t('template.goBackSaveConfirm')}`)) {
          this.goBack(false);
        }
    },
    goBack(confirmationNeeded) {
      // console.log("testing route.params", this.$route.params);
      if (confirmationNeeded) {
        if (confirm(`${this.$t('template.goBackDiscardConfirm')}`)) {
          this.wipeLocalStorage();
          this.$router.push({name: 'admin-settings', hash: '#deviceTemplates'})
        }
      } else {
        this.$router.push({name: 'admin-settings', hash: '#deviceTemplates'})
      }
    },
    fetchLatestVersion(versions) {
      if (versions?.length > 0) {
        versions.sort((a, b) => a?.versionNumber - b?.versionNumber);
        const num = versions[versions.length - 1].versionNumber;
        if (!isNaN(num)) this.latestVersion = num + 1;
      }
    },
    async createNewTemplate(sch) {
      let obj = {
        versionNumber: this.latestVersion,
        parametersSchema: sch
      };
      if (this.previousTemplate) {
        obj = {
          ...obj,
          installationFormSchema: this.previousTemplate.activeVersion.installationFormSchema,
          inspectionFormSchema: this.previousTemplate.activeVersion.inspectionFormSchema,
          deviceTemplate: `/device_templates/${this.previousTemplate.id}`
        }
      }
      try {
        const res = await api.post(`/device_template_schema_versions`, obj);
        console.log("testing result:", res);
        return res?.data?.id;
      } catch (error) {
        console.log(error);
      }
    },
    async sendData() {
      if (!this.areFieldNamesUnique) return;
      // creating the object to be sent
      this.$store.dispatch("setLoading", true);
      const schemaArray = this.schema.map((field) => {
        const { label, labels, name, type, conditionalLogic } = field
        const obj = {
          label,
          labels,
          name,
          type,
          conditionalLogic: this.sanitizeLogic(conditionalLogic)
        }
        if (field.type == "string") {
          return { ...obj, barcodeScanner: field.barcodeScanner}
        } else if (field.type == "list") {
          return { ...obj, choices: field.choices,}
        }
      });
      console.log("testing schema array:", schemaArray);
      console.log(this.previousSchema)

      // comment everything below for testing purposes

      const areAllPreviousFieldsPresent = this.previousSchema && this.previousSchema.every(field => {
        return schemaArray.find(newField => {
          return field.name == newField.name
        })
      })

      if (areAllPreviousFieldsPresent) {
        console.log("AllPreviousFieldsPresent - updating schema")
        const template = {
          name: this.name,
          nameTranslations: this.nameTranslations,
          description: this.description,
          descriptionTranslations: this.descriptionTranslations
        };
        const schemaVersion = {
          parametersSchema: schemaArray
        }
        try {
          const templateResult = await api.patch(`/device_templates/${this.previousTemplate.id}`, template, patchHeaders)
          const schemaResult = await api.patch(`/device_template_schema_versions/${this.previousTemplate.activeVersion.id}`, schemaVersion, patchHeaders)
          console.log(templateResult, schemaResult)
          this.handleDataSent()
        } catch (error) {
          console.log(error);
          this.$store.dispatch("setLoading", false);
        }
      } else {
        console.log("Fields changed - creating new version")
        const versionId = await this.createNewTemplate(schemaArray);
        if (!versionId) {
          console.log("error fetching version Id");
          this.$store.dispatch("setLoading", false);
          return;
        }
        // sending data
        if (this.latestVersion == 1) {
          const obj = {
            name: this.name,
            nameTranslations: this.nameTranslations,
            description: this.description,
            descriptionTranslations: this.descriptionTranslations,
            language: this.$i18n.locale,
            organisation: `/organisations/${this.organisation}`,
            isActive: false,
            versions: ["/device_template_schema_versions/" + versionId],
            activeVersion: "/device_template_schema_versions/" + versionId,
          };
          console.log("object to be sent:", obj);
          try {
            const res = await api.post(`/device_templates`, obj);
            console.log("testing result:", res);
            this.handleDataSent()
          } catch (error) {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          }
        } else {
          const newVersions = this.previousVersions.map(version => {return `/device_template_schema_versions/${version.id}`});
          newVersions.push(`/device_template_schema_versions/${versionId}`);
          const obj = {
            name: this.name,
            nameTranslations: this.nameTranslations,
            description: this.description,
            descriptionTranslations: this.descriptionTranslations,
            versions: newVersions,
            activeVersion: `/device_template_schema_versions/${versionId}`,
          };
          console.log("object to be re-sent:", obj);
          try {
            const res = await api.patch(`/device_templates/${this.previousTemplate.id}`, obj, patchHeaders );
            console.log("testing result:", res);
            this.handleDataSent()
          } catch (error) {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          }
        }
      }
    },
    async handleDataSent() {
      this.wipeLocalStorage();
      await this.$store.dispatch("getDeviceTemplates");
      this.$store.dispatch("setLoading", false);
      this.goBack(false);
    },

    clearAllData() {
      if (confirm(`${this.$t('template.clearDataConfirm')}`)) {
        this.wipeLocalStorage();
        this.name = "";
        this.description = "";
        this.nameTranslations = {};
        this.descriptionTranslations = {};
        this.schema = [];
        this.latestVersion = 1;
        this.previousVersions = [];
      }
    }
  },
};
</script>
