<template>
  <Menu as="div" class="relative h-full flex border-l border-gray-300">
    <div>
      <MenuButton class="px-2 rounded-r-md flex items-center bg-gray-50 h-full text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2 focus:ring-offset-gray-100">
        <span class="sr-only">Open options</span>
        <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute top-full right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <button
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block text-left w-full px-4 py-2 text-sm']"
              @click="$emit('add-and')"
            >
              {{$t("template.addCondition")}}
            </button>
          </MenuItem>
          <MenuItem v-if="allowDelete" v-slot="{ active }">
            <button
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block text-left w-full px-4 py-2 text-sm']"
              @click="$emit('add-or')"
            >
              {{$t("template.addConditionsGroup")}}
            </button>
          </MenuItem>
          <MenuItem v-if="allowDelete" v-slot="{ active }">
            <button
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block text-left w-full px-4 py-2 text-sm']"
              @click="$emit('remove')"
            >
              {{$t("template.removeConditionsGroup")}}
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EllipsisVerticalIcon } from '@heroicons/vue/24/solid'
export default {
  components: {
     Menu,
     MenuButton,
     MenuItem,
     MenuItems,
     EllipsisVerticalIcon
  },
  props: {
    allowDelete: {
      type: Boolean
    }
  }
}
</script>
