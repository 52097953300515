<template>
  <div>
    <div :class="buttonClasses" class="absolute bg-white p-1 flex">
      <button @click="() => visible = !visible" :title="$t('translations')">
        <LanguageIcon class="w-5 h-5" />
      </button>
    </div>
    <div v-if="visible" class="absolute left-0 top-full w-full z-10 bg-white space-y-1 rounded-md border border-gray-300 p-2">
      <div v-for="locale in $i18n.availableLocales" :key="locale" class="flex rounded-md shadow-sm">
        <span class="w-10 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-500 sm:text-sm">{{ locale }}</span>
        <input type="text" v-model="value[locale]" :disabled="locale == mainLanguage" :class="locale == mainLanguage ? 'cursor-not-allowed bg-gray-50' : ''" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-0.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ifgreen sm:text-sm sm:leading-6" :placeholder="translatedValue" />
      </div>
    </div>
  </div>
</template>

<script>
import { LanguageIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    LanguageIcon
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: Array,
    mainLanguage: String,
    translatedValue: String,
    buttonClasses: String
  },
  data () {
    return {
      visible: false,
      value: {},    
    }
  },
  methods: {
    closeOnClickOutside: function(event){
      if (this.$el && !this.$el.contains(event.target)){
        this.visible = false
      }
    }
  },
  created: function() {
    window.addEventListener('click', this.closeOnClickOutside, false);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeOnClickOutside, false);
  },
  watch: {
    mainLanguage: {
      immediate: true,
      handler () {
        this.value[this.mainLanguage] = this.translatedValue
      }
    },
    modelValue: {
      immediate: true,
      handler() {
        this.value = this.modelValue
        if (!this.value) this.value = {}
        this.$i18n.availableLocales.forEach(locale => {
          if (!Object.prototype.hasOwnProperty.call(this.value, locale)) {
            this.value[locale] = ''
          }
        })
      }
    },
    value: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', this.value)
      }
    }
  }
}
</script>
