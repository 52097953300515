<template>
  <li>
    <div class="px-4 py-4 sm:px-6 block">
      <div class="flex items-center justify-between">
        <div class="flex items-baseline">
          <p class="truncate text-sm font-medium mr-4">{{ index + 1 }}.</p>
          <RadioGroup
            :choices="fieldTypes"
            v-model="type"
          />
          <p class="ml-4 text-sm font-medium">{{ label }}</p>
        </div>
        <div class="ml-4 flex-shrink-0 relative z-0 inline-flex shadow-sm rounded-md">
          <button @click="$emit('toggle-unfolded')" :title="unfolded ? $t('collapse') : $t('expand')" type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <ChevronUpIcon v-if="unfolded" class="h-5 w-5" aria-hidden="true" />
            <ChevronDownIcon v-else class="h-5 w-5" aria-hidden="true" />
          </button>
          <button v-if="index != 0" @click="$emit('move-up')" :title="$t('moveUp')" type="button" class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <ArrowUpIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button v-if="index < (schemaLength - 1)" @click="$emit('move-down')" :title="$t('moveDown')" type="button" class="relative inline-flex items-center px-2 py-2 -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <ArrowDownIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button :title="$t('template.deleteField')" @click="$emit('delete')" type="button" id="edit-icon" :class="['-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
            <TrashIcon class='h-5 w-5' aria-hidden="true" />
          </button>
        </div>
      </div>
      <div v-show="unfolded" class="mt-4">
        <slot></slot>
      </div>
    </div>
  </li>
</template>
<script>

import { ChevronUpIcon, ChevronDownIcon, ArrowUpIcon, ArrowDownIcon, TrashIcon } from "@heroicons/vue/24/outline";
import RadioGroup from "@/components/inputs/RadioGroup.vue";
export default {
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    TrashIcon,
    RadioGroup,
  },
  emits: [
    'update:modelValue',
    'move-up',
    'move-down',
    'delete',
    'toggle-unfolded'
  ],
  props: {
    modelValue: String,
    index: Number,
    schemaLength: Number,
    fieldTypes: Array,
    label: String,
    unfolded: Boolean
  },
  data () {
    return {
      type: ""
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler () {
        this.type = this.modelValue
      }
    },
    type: {
      handler () {
        this.$emit('update:modelValue', this.type)
      }
    },
  }

};
</script>
