<template>
  <TemplateEditor v-if="viewBool" :previousTemplate="template" />
  <TemplateView v-else @changeView="changeView"/>
</template>
<script>
import TemplateEditor from "@/components/admin/DeviceTemplate/TemplateEditor.vue";
import TemplateView from "@/components/admin/DeviceTemplate/TemplateView.vue";
export default {
  components: { TemplateEditor, TemplateView },
  mounted() {
    this.viewBool = this.$route?.params?.deviceTemplateId == "new"
  },
  data() {
    return {
      viewBool: true,
      template: null
    }
  },
  // computed: {
  //   templateId() {
  //     console.log('testing template id inside admin: ' + this.$route?.params?.deviceTemplateId)
  //     return this.$route?.params?.deviceTemplateId;
  //   },
  // },
  // watch: {
  //   templateId(newv) {
  //     console.log('testing route value: ' + newv)
  //     this.viewBool = newv == "new"
  //   }
  // },
  methods: {
    changeView(arg) {
      console.log('argument inside change view:', arg)
      this.template = arg;
      this.viewBool = true;
    }
  }
};
</script>
