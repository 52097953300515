<template>
  <TemplateField
    v-model="value.type"
    :index="index"
    :schemaLength="schema.length"
    :fieldTypes="fieldTypes"
    :unfolded="value.unfolded"
    :label="value.label"
    @toggle-unfolded="value.unfolded = !value.unfolded"
    @move-up="$emit('move-up')"
    @move-down="$emit('move-down')"
    @delete="$emit('delete')"
  >
    <div class="-mx-4 sm:flex items-start sm:justify-between">
      <div class="px-4 w-1/2">
        <div class="relative">
          <InputWithErrorVue
            v-model="value.label"
            @update:modelValue="updateName"
            :lazy="true"
            :id="'label-' + index"
            :name="'label-' + index"
            type="text"
            :label="$t('template.fieldName')"
            :placeholder="$t('template.fieldName')"
            errorText=""
          />
          <TranslationInput  
            v-if="value.label != ''"
            v-model="value.labels"
            :mainLanguage="mainLanguage" 
            :translatedValue="value.label" 
            buttonClasses="top-8 right-3"
          />
        </div>
      </div>
      <div
        v-show="value.type == 'string'"
        class="mt-2 px-4 w-1/2 flex justify-end items-end sm:mt-0 self-end"
        title="Oznacz pole jako numer seryjny aby umożlwić skanowanie kodów kreskowych oraz wyszukiwanie urządzeń po wartości tego pola."
      >
        <LabeledSwitch
          :text="$t('public.infos.markAsSerialNo')"
          :initial="value.barcodeScanner"
          :active="false"
          @change="toggleBarcode"
          :border="false"
        />
      </div>
      <div v-show="value.type == 'list'" class="mt-2 px-4 w-1/2 flex flex-col justify-end items-end sm:mt-0">
        <ListOptionsEditor
          :name="value.name"
          v-model="value.choices"
          :mainLanguage="mainLanguage" 
        />
      </div>
    </div>
    <TemplateFieldConditional
      v-model="value.conditionalLogic"
      :schema="schema"
      :currentIndex="index"
      :areFieldNamesUnique="areFieldNamesUnique"
    />
  </TemplateField>
</template>
<script>

import TemplateField from "@/components/layout/TemplateField.vue";
import TemplateFieldConditional from "@/components/admin/DeviceTemplate/TemplateFieldConditional.vue";
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import TranslationInput from '@/components/inputs/TranslationInput.vue';
import LabeledSwitch from "@/components/inputs/LabeledSwitch.vue";
import ListOptionsEditor from "@/components/inputs/ListOptionsEditor.vue";
import slugify from 'slugify'
export default {
  components: {
    TemplateField,
    TemplateFieldConditional,
    InputWithErrorVue,
    TranslationInput,
    LabeledSwitch,
    ListOptionsEditor
  },
  emits: [
    'update:modelValue',
    'move-up',
    'move-down',
    'delete'
  ],
  props: {
    modelValue: Object,
    index: Number,
    areFieldNamesUnique: Boolean,
    mainLanguage: String,
    schema: Array
  },
  data () {
    return {
      value: {
        unfolded: false,
        label: "",
        labels: {},
        name: "",
        type: "string",
        barcodeScanner: true,
        choices: [],
        conditionalLogic: [[]],
      }
    }
  },
  computed: {
    slugifySettings () {
      return {
        replacement: '_',  // replace spaces with replacement character, defaults to `-`
        remove: undefined, // remove characters that match regex, defaults to `undefined`
        lower: true,      // convert to lower case, defaults to `false`
        strict: true,     // strip special characters except replacement, defaults to `false`
        locale: 'pl',       // language code of the locale to use
        trim: true         // trim leading and trailing replacement chars, defaults to `true`
      }
    },
    fieldTypes () {
      return [
        {value: 'string', name: `${this.$t('template.text')}`},
        {value: 'list', name: `${this.$t('template.list')}`}
      ]
    },
  },
  methods: {
    updateName() {
      this.value.name = slugify(this.value.label, this.slugifySettings)
    },
    toggleBarcode(event) {
      this.value.barcodeScanner = event.state;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.value = this.modelValue
        if (this.value.type == 'list' && !this.value.choices) this.value.choices = []
      }
    },
    value: {
      deep: true,
      handler () {
        console.log(this.value.conditionalLogic)
        this.$emit('update:modelValue', this.value)
      }
    },
  }

};
</script>
