<template>
  <div class="w-full sm:p-0">
    <div>
      <label for="add-list-item" class="pb-1 block text-sm font-medium text-gray-700">{{$t("template.addListOptions")}}</label>
    </div>
    <div class="flex rounded-md shadow-sm">
      <div class="relative flex flex-col items-stretch flex-grow focus-within:z-10">
        <input v-model="currentChoice" @keyup.enter="addListItem" type="text" name="text" class=" h-full focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" :placeholder="$t('template.listOptionPlaceholder')" />
      </div>
      <button @click="addListItem" @keyup.enter="addListItem" class="-ml-px relative inline-flex items-center space-x-2 px-2 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
        <PlusIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </button>
    </div>
  </div>
  <draggable
    v-model="choices"
    :group="name"
    @start="drag=true"
    @end="drag=false"
    :item-key="(element) => { return element.value }"
    class="w-full mt-2"
  >
    <template #item="{element, index}">
      <li class="py-2 relative">
        <div class="flex items-center justify-between space-x-4">
          <div class="flex-1 min-w-0">
            <p class="text-sm font-medium text-gray-900 truncate cursor-move">
              {{ element.value }}
            </p>
          </div>
          <div class="flex items-center">
            <button @click="removeListItem(index)" class=" inline-flex">
              <MinusCircleIcon class="w-6 h-6" />
            </button>
          </div>
        </div>
        <TranslationInput  
          v-model="choices[index].values"
          :translatedValue="element.value"
          :mainLanguage="mainLanguage" 
          buttonClasses="top-1.5 right-6"
        />
      </li>
    </template>
  </draggable>
</template>

<script>
import { MinusCircleIcon, PlusIcon } from "@heroicons/vue/24/outline";
import TranslationInput from "@/components/inputs/TranslationInput.vue";
import draggable from 'vuedraggable'
export default {
  components: {
    TranslationInput,
    MinusCircleIcon,
    PlusIcon,
    draggable
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: Array,
    mainLanguage: String,
    name: String
  },
  data () {
    return {
      choices: [],
      currentChoice: ""
    }
  },
  methods: {
    removeListItem(listIndex) {
      console.log(listIndex)
      console.log(this.choices.splice(listIndex, 1));
    },
    addListItem() {
      if (this.currentChoice != "") {
        this.choices.push({
          value: this.currentChoice,
          values: {}
        });
        this.currentChoice = ""
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.choices = this.modelValue
      }
    },
    choices: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', this.choices)
      }
    }
  }
}
</script>
