<template>
  <div v-if="currentIndex > 0 && areFieldNamesUnique">
    <hr class="mt-5 mb-4" />
    <div class="block text-sm text-gray-700 font-medium mb-1">
      {{$t("template.conditionalDisplay")}}
    </div>
    <div
      v-for="(conjunctions, conindex) in alternatives"
      :key="conindex"
    >
      <div v-if="conindex > 0" class="relative my-1">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-white text-gray-500"> {{$t('or')}} </span>
        </div>
      </div>
      <div class="relative border border-gray-400 rounded-md p-2 pr-11">
        <div class="absolute right-0 top-0 bottom-0 rounded-r-md">
          <ConditionalOptionsMenu
            :allow-delete="alternativeCheck(conindex)"
            @remove="removeAlternative(conindex)"
            @add-and="addConjunction(conindex)"
            @add-or="addAlternative"
          />
        </div>
        <div v-for="(item, index) in conjunctions" :key="index" >
          <div v-if="index > 0" class="relative my-1">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500"> {{$t('and')}} </span>
            </div>
          </div>
          <div class="w-full flex items-end space-x-2">
            <div class="w-1/3">
              <NativeSelect
                :label="$t('template.field')"
                :options="listOptions"
                v-model="alternatives[conindex][index].field"
              />
            </div>
            <div class="w-1/4">
              <NativeSelect
                :label="$t('template.operator')"
                :options="[
                  {name: `${this.$t('template.isIn')}`, value: 'in'},
                  {name: `${this.$t('template.isNotIn')}`, value: 'notin'}
                ]"
                v-model="conjunctions[index].operator"
              />
            </div>
            <div class="w-1/4 flex-grow">
              <InputWithErrorVue
                v-if="fieldType(item.field) == 'string'"
                :label="$t('template.value')"
                v-model="alternatives[conindex][index].value[0]"
                type="text"
              />
              <CheckboxGroup
                v-else-if="fieldType(item.field) == 'list'"
                :choices="fieldChoices(item.field)"
                v-model="alternatives[conindex][index].value"
              />
            </div>
            <button 
              :title="$t('template.removeCondition')"
              @click="removeCondition(index, conindex)"
              type="button"
              class="-ml-px relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen"
            >
              <TrashIcon class='h-5 w-5' aria-hidden="true" />
            </button>
          </div>
        </div>
        <span class="text-sm text-gray-400" v-if="conjunctions.length == 0">{{$t("template.useMenu")}}</span>
      </div>
    </div>
  </div>
  <div v-if="!areFieldNamesUnique">
    <hr class="mt-4 mb-5" />
    <div class="block text-sm font-medium text-red-400">
      {{$t('template.conditionalDisplayText')}}
    </div>
  </div>
</template>
<script>
import NativeSelect from "@/components/inputs/NativeSelect.vue";
import ConditionalOptionsMenu from "@/components/inputs/ConditionalOptionsMenu.vue";
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import CheckboxGroup from "@/components/inputs/CheckboxGroup.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
export default {
  components: {
    NativeSelect,
    InputWithErrorVue,
    ConditionalOptionsMenu,
    CheckboxGroup,
    TrashIcon
  },
  props: {
    schema: Array,
    currentIndex: Number,
    areFieldNamesUnique: Boolean,
    modelValue: Array,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      chosen: null,
      alternatives: [[]],
    };
  },
  computed: {
    listOptions() {
      return this.schema
        .filter((field, index) => {return index < this.currentIndex})
        .map(field => {return {name: field.label, value: field.name, type: field.type}})
    },
  },
  watch: {
    alternatives: {
      deep: true,
      handler() {
        this.$emit("update:modelValue", this.alternatives);
      },
    },
    modelValue: {
      immediate: true,
      deep: true,
      handler() {
        this.alternatives = this.modelValue;
      },
    },
  },
  methods: {
    alternativeCheck(conind) {
      return (this.alternatives.length > 1 || this.alternatives[conind].length > 0)
    },
    removeAlternative(conind) {
      if (this.alternatives.length > 1) this.alternatives.splice(conind, 1);
      else this.alternatives[0] = [];
    },
    removeCondition(ind, conind) {
      this.alternatives[conind].splice(ind, 1);
      if (this.alternatives[conind].length == 0) this.removeAlternative(conind)
    },
    fieldType(name) {
      const item = this.listOptions?.find((option) => option.value == name);
      return item?.type;
    },
    fieldChoices(name) {
      const item = this.schema?.find((field) => field.name == name);
      return item?.choices;
    },
    addAlternative() {
      this.alternatives.push([{
        field: null,
        value: [null],
        operator: "in"
      }]);
    },
    addConjunction(conind) {
      this.alternatives[conind].push({
        field: null,
        value: [null],
        operator: "in"
      });
    }
  }
};
</script>
