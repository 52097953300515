<template>
  <div class="relative">
    <label v-if="dropdown" class="block text-sm font-medium text-gray-700 mb-1">Wartości</label>
    <button v-if="dropdown" @click="show = !show" class="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2 focus:ring-offset-gray-100">
      Opcje
      <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
    </button>
    <div v-if="show || !dropdown" :class="dropdown ? 'absolute w-54 top-full mt-1 z-10 py-1 px-4 right-0 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none' : ''">
      <div
        v-for="choice in choices"
        :key="choice.value"
        class="inline-block relative flex items-start py-1"
      >
        <div class="flex h-5 items-center">
          <input
            :id="choice.value"
            :value="choice.value"
            type="checkbox"
            @change="handleChange"
            class="h-4 w-4 rounded border-gray-300 text-ifgreen focus:ring-ifgreen"
            v-model="items"
          />
        </div>
        <label :for="choice.value" class="ml-2 text-sm text-gray-700">{{ choice.name || choice.value }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
  name: "CheckboxGroup",
  emits: ['update:modelValue'],
  components: {
    ChevronDownIcon
  },
  props: {
    choices: Array,
    modelValue: Array,
    dropdown: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      items: [],
    };
  },
  methods: {
    closeOnClickOutside: function(event){
      if (!this.$el.contains(event.target)){
        this.show = false
      }
    },
    handleChange () {
      this.$emit('update:modelValue', this.items)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler () {
        this.items = this.modelValue
      }
    }
  },
  created() {
    window.addEventListener('click', this.closeOnClickOutside, false);
  },
  beforeUmount() {
    window.removeEventListener('click', this.closeOnClickOutside, false);
  }
};
</script>
