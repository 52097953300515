<template>
  <div>
    <SwitchGroup as="div" :class="[border ? 'py-6 px-2' : 'py-2', disabled ? 'pointer-events-none opacity-60' : '', active ? 'bg-gray-50' : '']" class="flex items-center justify-between">
      <SwitchLabel v-if="title || text" as="span" class="flex-grow flex flex-col pr-2" passive>
        <span class="text-sm font-medium text-gray-900">{{ title }}</span>
        <span class="text-sm text-gray-500">{{ text }}</span>
      </SwitchLabel>
      <Switch v-model="enabled" :class="[enabled ? 'bg-ifgreen' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen']">
        <span class="sr-only">Use setting</span>
        <span :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
          <span :class="[enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
            <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span :class="[enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
            <svg class="h-3 w-3 text-ifgreen" fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </SwitchGroup>
    <div v-if="border" class="inset-0 flex items-center">
      <div class="w-full border-t border-gray-300" />
    </div>
  </div>
</template>

<script>
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default {
  name: "LabeledSwitch",
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  emits: ['change'],
  props: {
    title: String,
    text: String,
    initial: Boolean,
    disabled: Boolean,
    active: Boolean,
    pkey: Number,
    border: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      enabled: this.initial
    }
  },
  watch: {
    enabled () {
      this.$emit('change',{state: this.enabled, key: this.pkey})
    }
  }
};
</script>
